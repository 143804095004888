<template>
  <div>

    <ConfirmDialog ref="confirmDialog"/>
    <Overlay :overlay="overlay"/>

    <v-alert border="bottom"
             class="fs-14"
             color="info"
             dark>
      - Ce point de vente a été lié avec l'application Gifty. <br>
      - Vous pouvez lui envoyer la récompense en cliquant sur le bouton ci-dessous.<br>
      - <span class="font-weight-medium">Note : </span> le nombre de récompense aujourd'hui est :
      <span class="fs-15">[ {{ reward_count }} ]</span>
    </v-alert>
    <v-btn :disabled="reward_count >= 2"
           block
           class="mt-5"
           color="primary"
           depressed
           @click="reward">
      Récompenser
      <v-icon right>mdi-gift</v-icon>
    </v-btn>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    props: ['code', 'reward_count'],
    data() {
        return {
            overlay: false
        }
    },
    methods: {
        async reward() {
            if (await this.$refs.confirmDialog.open('Confirmation', 'Etes-vous sûr de vouloir envoyer une récompense à ce point de vente ?', {
                icon: 'mdi-information',
                agreeText: 'Récompenser',
                color: 'orange',
            })) {
                this.overlay = true
                HTTP.post('gifty/reward', {
                    code: this.code
                }).then(() => {
                    this.overlay = false
                    this.$successMessage = 'La récompense a été envoyée avec succès.'
                    this.$router.push('/supervisor-view/gifty').catch(() => {
                    })
                    this.$emit('reset')
                }).catch(err => {
                    this.$errorMessage = 'Une erreur de serveur s\'est produite. Veuillez réessayer.'
                    this.overlay = false
                    console.log(err)
                })
            }
        },
    }
}
</script>

<style scoped>

</style>