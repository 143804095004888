<template>
  <div>

    <Overlay :overlay="overlay"/>

    <div class="text-center mb-lg-8 mb-5">
      <v-avatar v-if="user.image" size="100">
        <v-img :src="imageGifty(user.image)"
               class="grey lighten-4"></v-img>
      </v-avatar>

      <v-avatar v-else
                :class="$func.randomColor()"
                class="font-weight-medium avatar-border"
                size="100">
                     <span class="fs-20">
                       {{ $func.avatarName(user.name) }}
                     </span>
      </v-avatar>
    </div>

    <v-alert v-if="errorMessage"
             border="bottom"
             class="fs-14"
             dense
             dismissible
             prominent
             text
             type="warning">
      {{ errorMessage }}
    </v-alert>

    <v-simple-table class="rounded-lg table-border">
      <template v-slot:default>
        <tbody>
        <tr>
          <td class="font-weight-medium text-no-wrap py-4" colspan="2">
            <v-avatar>
              <GiftyIcon/>
            </v-avatar>
            Information du compte GIFTY
          </td>
        </tr>
        <tr class="grey lighten-4">
          <td class="font-weight-medium">
            <v-icon dense left>mdi-account</v-icon>
            Nom complet
          </td>
          <td>{{ user.name }}</td>
        </tr>
        <tr>
          <td class="font-weight-medium text-no-wrap">
            <v-icon dense left>mdi-phone</v-icon>
            N° de téléphone
          </td>
          <td>{{ user.phone }}</td>
        </tr>
        <tr class="grey lighten-4">
          <td class="font-weight-medium text-no-wrap">
            <v-icon dense left>mdi-calendar-heart</v-icon>
            Date de naissance
          </td>
          <td>{{ user.dob }}</td>
        </tr>
        <tr>
          <td class="font-weight-medium text-no-wrap">
            <v-icon dense left>mdi-gender-male-female</v-icon>
            Sexe
          </td>
          <td>{{ user.gender === 'male' ? 'Homme' : 'Femme' }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div v-if="!success">

      <v-btn block
             class="mt-5"
             color="secondary"
             depressed
             @click="linkAccount">
        <v-icon left>mdi-account-check-outline</v-icon>
        Relier ce compte
      </v-btn>

      <v-btn block
             class="mt-3 bg-red-subtle"
             color="red"
             text
             @click="[$emit('back'),$emit('scan',true)]">
        <v-icon left>mdi-chevron-left</v-icon>
        Retour
      </v-btn>
    </div>

    <div v-else class="mt-5">
      <RewardBtn :code="code" :reward_count="reward_count"/>

      <v-btn block
             class="mt-3 bg-red-subtle"
             color="red"
             exact
             text
             to="/supervisor-view/gifty">
        <v-icon left>mdi-chevron-left</v-icon>
        Retour à la page de recherche
      </v-btn>

    </div>

  </div>
</template>

<script>
import RewardBtn from "@/views/supervisor-view/gifty/components/RewardBtn.vue";
import {HTTP} from "@/http-common";
import GiftyIcon from "@/components/svg-icons/GiftyIcon.vue";

export default {
    props: ['user', 'code', 'reward_count'],
    components: {GiftyIcon, RewardBtn},
    data() {
        return {
            overlay: false,
            success: false,
            errorMessage: false,
        }
    },
    methods: {
        linkAccount() {
            this.overlay = true
            this.errorMessage = null
            HTTP.post('/gifty/store-giftyid', {
                id: this.$route.params.id,
                gifty_id: this.user.account.id,
            }).then(() => {
                this.overlay = false
                this.success = true
                this.$successMessage = "Ce point de vente a été relié avec succès."
            }).catch(err => {
                this.overlay = false
                if (err.response.status === 400) {
                    this.errorMessage = err.response.data.message
                }
                this.$errorMessage = "Une erreur s'est produite, veuillez réessayer !"
                console.log(err)
            })
        },
        imageGifty(image) {
            return process.env.VUE_APP_GIFTY_URL + '/' + image
        }
    }
}
</script>

<style scoped>

</style>