<template>
  <div>

    <v-dialog v-model="dialog"
              :fullscreen="$vuetify.breakpoint.mobile"
              max-width="600"
              persistent
              scrollable>
      <v-card tile>
        <v-card-title>
          Numéro de téléphone sur Gifty
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">
          <v-overlay :value="isLoading"
                     color="white"
                     opacity="0.7">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="50"
            ></v-progress-circular>
          </v-overlay>

          <form v-if="!Object.keys(user).length" @submit.prevent="getAccountId">


            <v-alert v-if="notFound" border="bottom" class="fs-14"
                     dense dismissible prominent type="error">
              Aucun compte Gifty ne correspond à ce numéro !
            </v-alert>

            <v-text-field v-model="phone"
                          v-number
                          clearable
                          counter="9"
                          outlined
                          persistent-hint
                          placeholder="Entrez le numéro de téléphone sur Gifty.."
                          prefix="+213"
                          prepend-inner-icon="mdi-phone"
                          required
                          type="number"
            ></v-text-field>

            <v-btn :disabled="!phone || phone.length !== 9"
                   block
                   class="mt-3"
                   color="primary"
                   depressed
                   type="submit">
              <v-icon left>mdi-reload</v-icon>
              Vérifier
            </v-btn>

          </form>

          <div v-else>
            <CheckResult :code="code" :reward_count="reward_count" :user.sync="user" @back="user = {}"/>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios"
import CheckResult from "@/views/supervisor-view/gifty/components/CheckResult.vue";

export default {
    props: ['code', 'reward_count'],
    components: {CheckResult},
    data() {
        return {
            dialog: false,
            isLoading: false,
            phone: '',
            user: {},
            notFound: false,
        }
    },
    methods: {
        open() {
            this.dialog = true
            this.phone = ''
            this.user = {}
        },
        getAccountId() {
            this.isLoading = true
            this.notFound = false
            this.user = {}
            let phone = '+213' + this.phone
            axios.get(process.env.VUE_APP_GIFTY_URL + '/api/user/phone/' + phone).then((res) => {
                this.isLoading = false
                this.user = res.data
                if (!Object.keys(res.data).length) {
                    this.notFound = true
                }
            }).catch(err => {
                this.isLoading = false
                this.$errorMessage = "Une erreur s'est produite, veuillez réessayer !"
                console.log(err)
            })
        }
    }
}
</script>

<style scoped>

</style>