<template>
  <div>
    <v-dialog v-model="dialog"
              :fullscreen="$vuetify.breakpoint.mobile"
              max-width="600"
              persistent
              scrollable>
      <v-card tile>
        <v-card-title>
          Scanner le QR code Gifty
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-4">

          <v-overlay :value="isLoading"
                     color="white"
                     opacity="0.7">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="50"
            ></v-progress-circular>
          </v-overlay>

          <div v-if="!Object.keys(user).length">

            <v-alert v-if="notFound" border="bottom" class="fs-14"
                     dense dismissible prominent type="error">
              Aucun compte Gifty ne correspond à ce numéro !
            </v-alert>

            <qrcode-stream v-if="scan" @decode="onDecode"/>

          </div>

          <div v-else>
            <CheckResult :code="code" :reward_count="reward_count" :user.sync="user" @back="user = {}"
                         @scan="scan = $event"/>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'
import axios from "axios"
import CheckResult from "@/views/supervisor-view/gifty/components/CheckResult.vue";


export default {
    props: ['code', 'reward_count'],
    components: {
        CheckResult,
        QrcodeStream
    },
    data() {
        return {
            dialog: false,
            scan: false,
            isLoading: false,
            user: {},
            notFound: false
        }
    },
    methods: {
        open() {
            this.dialog = true
            this.scan = true
            this.user = {}
        },
        getAccountId(userId) {
            this.isLoading = true
            this.notFound = false
            this.user = {}
            axios.get(process.env.VUE_APP_GIFTY_URL + '/api/user/' + userId).then((res) => {
                this.isLoading = false
                this.user = res.data
                if (!Object.keys(res.data).length) {
                    this.notFound = true
                }
                this.scan = false
            }).catch(err => {
                this.isLoading = false

                /**
                 * Refresh scan
                 * @type {boolean}
                 */
                this.scan = false
                let _vm = this
                setTimeout(function () {
                    _vm.scan = true
                }, 500)

                this.$errorMessage = "Une erreur s'est produite, veuillez réessayer !"
                console.log(err)
            })
        },
        onDecode(result) {
            if (result) {
                if (result.toString().length > 6) {
                    let res = JSON.parse(result)
                    let userId = res.userId
                    this.getAccountId(userId)
                } else {
                    this.getAccountId(result)
                }
            }
        },
    }
}
</script>

<style scoped>

</style>