<template>
  <div>
    <v-container fluid>

      <Overlay :overlay="overlay"/>
      <Lightbox ref="lightbox"/>

      <v-row justify="center">
        <v-col cols="12" lg="8">

          <v-card class="rounded-lg shadow">

            <v-card-title>
              Lié le pos avec Gifty
              <v-spacer/>
              <v-btn color="primary"
                     exact
                     left
                     text
                     to="/supervisor-view/gifty">
                <v-icon left>mdi-chevron-left</v-icon>
                Retour
              </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text class="pa-6 pa-lg-10">

              <v-layout v-if="overlay" align-center class="text-center mb-lg-8 mb-5" justify-center>
                <v-flex shrink>
                  <div>
                    <v-skeleton-loader class="avatar-loader" type="avatar"></v-skeleton-loader>
                  </div>
                </v-flex>
              </v-layout>

              <div v-else class="text-center mb-lg-8 mb-5">
                <v-avatar v-if="salepoint.image" size="100">
                  <v-img :src="$baseUrl + salepoint.image"
                         class="zoom-pointer"
                         @click="$refs.lightbox.open(salepoint.image)"></v-img>
                </v-avatar>

                <v-avatar v-else
                          :class="$func.randomColor()"
                          class="font-weight-medium avatar-border"
                          size="100">
                     <span class="fs-20">
                       {{ $func.avatarName(salepoint.name) }}
                     </span>
                </v-avatar>
              </div>

              <v-text-field v-model="salepoint.name"
                            dense
                            disabled
                            label="Nom du pos"
                            outlined
                            prepend-inner-icon="mdi-account"/>

              <v-text-field v-model="salepoint.code"
                            dense
                            disabled
                            label="Code du pos"
                            outlined
                            prepend-inner-icon="mdi-account"/>

              <v-textarea v-model="salepoint.address"
                          dense
                          disabled
                          label="Adresse"
                          outlined
                          prepend-inner-icon="mdi-map-marker"
                          rows="2"/>

              <v-row>

                <v-col cols="12" lg="6">
                  <v-card class="rounded-lg" outlined @click="$refs.phoneDialog.open()">
                    <v-card-text class="pa-0">
                      <div class="d-flex align-center">
                        <div class="grey lighten-5 rounded-tl-lg rounded-bl-lg">
                          <v-icon class="pa-4" color="primary" size="30">mdi-phone</v-icon>
                        </div>
                        <v-divider vertical/>
                        <div class="ml-3 grey--text text--darken-3 font-weight-medium">
                          Par numéro de téléphone
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" lg="6">
                  <v-card class="rounded-lg" outlined @click="$refs.scanDialog.open()">
                    <v-card-text class="pa-0">
                      <div class="d-flex align-center">
                        <div class="grey lighten-5 rounded-tl-lg rounded-bl-lg">
                          <v-icon class="pa-4" color="primary" size="30">mdi-qrcode-scan</v-icon>
                        </div>
                        <v-divider vertical/>
                        <div class="ml-3 grey--text text--darken-3 font-weight-medium">
                          Scanner le qr code
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

              </v-row>

            </v-card-text>

          </v-card>
        </v-col>
      </v-row>

      <ScanDialog ref="scanDialog" :code="salepoint.code" :reward_count="salepoint.reward_count"/>
      <PhoneDialog ref="phoneDialog" :code="salepoint.code" :reward_count="salepoint.reward_count"/>

    </v-container>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import ScanDialog from "@/views/supervisor-view/gifty/components/ScanDialog.vue";
import PhoneDialog from "@/views/supervisor-view/gifty/components/PhoneDialog.vue";

export default {
    components: {PhoneDialog, ScanDialog},
    data() {
        return {
            overlay: false,
            salepoint: {},
        }
    },
    methods: {
        showPos() {
            this.overlay = true
            HTTP.get('/gifty/show-pos', {
                params: {
                    id: this.$route.params.id
                }
            }).then((res) => {
                this.overlay = false
                this.salepoint = res.data.data
            }).catch(err => {
                this.overlay = false
                if (err.status === 404) {
                    this.$router.push('/404')
                }
                console.log(err)
            })
        },
    },
    created() {
        this.showPos()
    }
}
</script>

<style>
.avatar-loader .v-skeleton-loader__avatar {
    height: 100px;
    width: 100px;
}
</style>